<template>
  <div class="lock-log">
    <div class="title">
      <app-header :title="title" :isShowBack="true"></app-header>
    </div>
    <app-list :opt="opt" @get="onGet" ref="list"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      title: "查看历史",
      opt: {
        search: [
        ],
        columns: [
          { label: "房号", key: "roomNo", width: 80 },
          { label: "房客", key: "validationType" },
          { label: "抄表时间", key: "borrowTime" },
          { label: "抄表数据", key: "phoneNo" },
          { label: "本次用量（度）", key: "RoomName" },
          { label: "累积用量（度）", key: "RoomName" },
        ]
      },
      row: {},
      id: "",
      apartmentName: "",
      location: "",
      mac: ""
      //   roomIP: ""
      //   roomstatus: ""
    };
  },
  activated() {
    console.log("lock-log activated!!");
    // this.row = this.cache.get("separateAccessControl");
    this.$route.params.id;
    this.$refs.list.onRefresh();
    this.getOrdeData(this.$route.params.id);
  },
  methods: {
    onGet(opt) {
      console.log(opt.searchForm);
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        gatewayId: this.$route.params.id,
        beginTime: opt.searchForm["date"] ? opt.searchForm["date"][0] : "",
        endTime: opt.searchForm["date"] ? opt.searchForm["date"][1] : "",
        validateType: opt.searchForm["validateType"]
          ? opt.searchForm["validateType"]
          : ""
      };
      this.post("/device-service/L2/pageQuery/borrowRecord", dto, {
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          item.validationType = ["手机号+密码", "手机号+验证码"][
            item.validationType - 1
          ];
          item.borrowTime =
            item.borrowTime == null ? "" : this.format(item.borrowTime);
          item.unlockDate =
            item.unlockDate == null ? "" : this.format(item.unlockDate);
          item.returnTime =
            item.returnTime == null ? "" : this.format(item.returnTime);

          item.RoomName =
            item.apartmentName + item.buildNo + "栋" + item.roomNo + "房";
        }
        opt.cb(res.data);
      });
    },
    // 获取订单信息
    getOrdeData(roomId) {
      this.get("/device-service/L2/detail/" + roomId).then(res => {
        console.log(res);
        this.apartmentName = res.apartmentName;
        this.location = res.location;
        this.mac = res.mac;
        // this.roomIP = res.ip;
        // this.roomstatus = res.lockType;
      });
    }
  }
};
</script>
<style lang="scss">
.lock-log {
  .danger {
    color: #f56c6c;
  }
  .info1 {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
    .txt {
      margin-right: 20px;
    }
  }
  .info {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
    background-color: #e4e7ed;

    .txt {
      margin-right: 20px;
    }
  }
}
</style>
